import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyAj3nMp-2NmH-8dnn77oqdtRMcq_C-oUiw",
  authDomain: "park-street-manor-apartments.firebaseapp.com",
  databaseURL: "https://park-street-manor-apartments.firebaseio.com",
  projectId: "park-street-manor-apartments",
  storageBucket: "park-street-manor-apartments.appspot.com",
  messagingSenderId: "1078646855116",
  appId: "1:1078646855116:web:50af709c24500bfb35f969",
  measurementId: "G-W0DV398EY5"
}

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
